import React from 'react';
import { Styled, Box } from 'theme-ui';
import ReferredBlock from 'gatsby-theme-andy/src/components/ReferredBlock';

export default function Footer({ references }) {
  return (
    <Box p={3} sx={{ borderRadius: 2 }} mb={2} bg="accent" color="text-light">
      <ReferredBlock references={references} />
      <p sx={{ m: 0, fontSize: 1 }}>
        This project is 100% free to the general public, there will be no ads or subscription to access it. 
        Feel free to benefit from it as long you attribute/reference it 
      </p>
      <p sx={{ m: 0, fontSize: 1 }}>
        Codex is part of <Styled.a
          sx={{ textDecoration: 'underline', color: 'links' }}
          href="https://www.reconfigured.co"
          target="_blank"
        >
          Reconfigured
        </Styled.a>. All articles written there might be sourced from here or external sources, if you wish to support 
        this project and other elements we produce (podcast, newsletter, and much more) then consider becoming a member on Reconfigured  {' '}
        <Styled.a
          sx={{ textDecoration: 'underline', color: 'text-light' }}
          href="https://www.reconfigured.co/membership"
          target="_blank"
        >
          here
        </Styled.a>
      </p>
    </Box>
  );
}